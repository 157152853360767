import OWalletImage from 'assets/images/owallet.png';
import cn from 'classnames/bind';
import Modal from 'components/Modal';
import RenderWhen from 'components/RenderWhen';
import useConfigReducer from 'hooks/useConfigReducer';
import useTheme from 'hooks/useTheme';
import Keplr from 'libs/keplr';
import { initClient, isAndroid } from 'libs/utils';
import React from 'react';
import styles from './ConnectWalletModal.module.scss';
import LoginWidgetDeepLink from './LoginDeepLinkMobile';
import LoginWidget from './LoginWidget';
import { CosmosWalletType, cosmosWalletProvider, cosmosWallets } from './constants';
import useWalletReducer from 'hooks/useWalletReducer';

const cx = cn.bind(styles);

interface ConnectWalletModalProps {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  address: string;
  disconnectKeplr: () => Promise<void>;
  connectKeplr: () => Promise<void>;
}

const ConnectWalletModal: React.FC<ConnectWalletModalProps> = ({
  isOpen,
  close,
  connectKeplr,
  disconnectKeplr,
  address,
  open
}) => {
  const mobileMode = /(android)/i.test(navigator.userAgent);
  const { theme } = useTheme();
  const [oraiAddress, setOraiAddress] = useConfigReducer('address');
  const [walletByNetworks, setWalletByNetworks] = useWalletReducer('walletsByNetwork');
  const { networkType } = cosmosWalletProvider;
  const currentWallet = walletByNetworks.cosmos;

  const handleConnectWallet = async (walletType: CosmosWalletType) => {
    try {
      window.Keplr = new Keplr(walletType);
      localStorage.setItem('typeWallet', walletType);

      await initClient();

      const oraiAddr = await window.Keplr.getKeplrAddr();
      setOraiAddress(oraiAddr);
      setWalletByNetworks({
        ...walletByNetworks,
        [networkType]: walletType
      });
    } catch (error) {
      console.trace({ errorCosmos: error });
      setWalletByNetworks({
        ...walletByNetworks,
        [networkType]: null
      });

      setOraiAddress(undefined);
      localStorage.removeItem('typeWallet');

      console.log(error?.message ?? JSON.stringify(error));
    }
  };

  const handleDisconnectWallet = async (registryName: string) => {
    setWalletByNetworks({
      ...walletByNetworks,
      [networkType]: null
    });

    setOraiAddress(undefined);

    // TODO: need to refactor later
    if (walletByNetworks.cosmos === 'eip191') {
      localStorage.removeItem('eip191-account');
    }

    localStorage.removeItem('typeWallet');
  };

  return (
    <Modal isOpen={isOpen} close={close} open={open} isCloseBtn={true}>
      <div className={cx('select-box', theme)}>
        <div className={cx('title')}>
          <div>Connect wallet</div>
        </div>
        <div className={cx('options')}>
          <RenderWhen isTrue={mobileMode}>
            <RenderWhen.If isTrue={!address}>
              <a
                target="__blank"
                href={
                  isAndroid()
                    ? `app.owallet.oauth://google/open_url?url=${encodeURIComponent('https://app.oraidex.io')}`
                    : `owallet://open_url?url=${encodeURIComponent('https://app.oraidex.io')}`
                }
              >
                <LoginWidgetDeepLink
                  text="Connect OWallet"
                  address={address}
                  logo={OWalletImage}
                  disconnect={disconnectKeplr}
                />
              </a>
            </RenderWhen.If>
            <RenderWhen.If isTrue={!!address}>
              <LoginWidgetDeepLink
                text="Connect OWallet"
                address={address}
                logo={OWalletImage}
                disconnect={disconnectKeplr}
              />
            </RenderWhen.If>
          </RenderWhen>
          <RenderWhen>
            <RenderWhen.If isTrue={!mobileMode}>
              {cosmosWallets
                .filter((w) => !currentWallet || (currentWallet && w.registryName === currentWallet))
                .map((wallet, index) => {
                  return (
                    <LoginWidget
                      key={index}
                      text={wallet.name}
                      address={address}
                      logo={wallet.icon}
                      registryName={wallet.registryName}
                      connect={() => handleConnectWallet(wallet.registryName)}
                      disconnect={() => handleDisconnectWallet(wallet.registryName)}
                    />
                  );
                })}
            </RenderWhen.If>
          </RenderWhen>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectWalletModal;
