import React, { ReactNode, memo, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { LogoDark, LogoLight } from 'assets/images';
import LightDarkMode from './LightDarkMode';
import ConnectWallet from './ConnectWallet';
import { useNavigate } from 'react-router-dom';
import useTheme from 'hooks/useTheme';
// import TopRanking from './TopRanking';
import SelectSwapTokenWrapper from './SelectSwapToken';
import useWindowSize from 'hooks/useWindowSize';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/layout/external_link.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/layout/home.svg';
import { ReactComponent as FutureIcon } from 'assets/icons/layout/future_icon.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/layout/helper_icon.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/arrow_down.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/layout/ic_support.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/layout/ic_telegram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/layout/ic_twitter.svg';
import TooltipContainer from 'components/TooltipContainer';
import Drawer from 'components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { selectOpenMenuState, updateOpenMenuHeader } from 'redux/reducers/actionSlice';

export const MINIMUM_SWAP = 0.01;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [otherActive, setOtherActive] = useState(false);
  const { isMobileView, isMobileHorizontalView, isIpadView } = useWindowSize();
  const dispatch = useDispatch();
  const openMenu = useSelector(selectOpenMenuState);

  const isMobileMode = isMobileView || isMobileHorizontalView || isIpadView;

  const renderLink = (to: string, title: string, Icon?: ReactNode, showExternal = false) => {
    return (
      <a target="_blank" href={to} className={classNames(styles.menu_item)} rel="noreferrer">
        {Icon}
        <span className={classNames(styles.menu_item_text)}>{title}</span>
        {showExternal && (
          <div className={styles.hoverIcon}>
            <ExternalLinkIcon />
          </div>
        )}
      </a>
    );
  };

  if (isMobileMode) {
    return (
      <Drawer
        showClose={false}
        visible={openMenu}
        contentWidth={290}
        position={'left'}
        close={() => dispatch(updateOpenMenuHeader(false))}
      >
        <div className={classNames(styles.drawer_menu, styles[theme])}>
          <div onClick={() => navigate('/')} className={styles.headerLogo}>
            <img src={theme === 'light' ? LogoDark : LogoLight} alt="logo" />
          </div>
          <div className={styles.divider}></div>

          {renderLink('https://app.oraidex.io', 'Home', <HomeIcon />, true)}
          {renderLink('https://futures.oraidex.io', 'Futures Trading', <FutureIcon />, true)}

          <div className={styles.divider}></div>
          <div
            onClick={() => {
              setOtherActive(!otherActive);
            }}
            className={classNames(styles.menu_item)}
          >
            <HelpIcon />
            <span className={classNames(styles.menu_item_text)}>Help</span>

            <div className={styles.flexEnd}>
              <DownArrowIcon />
            </div>
          </div>

          {otherActive && (
            <div className={styles.help_list}>
              {renderLink('https://t.me/oraidex', 'Join our Community', <TelegramIcon />)}
              {renderLink('https://twitter.com/oraidex', 'Twitter', <TwitterIcon />)}
              {renderLink('https://t.me/SamORAI_bot', 'Contact us', <SupportIcon />)}
            </div>
          )}

          <div className={styles.connect}>
            <ConnectWallet />
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <div className={classNames(styles.header, styles[theme], { [styles.mobile]: !!isMobileMode })}>
      <div className={styles.left}>
        <div onClick={() => navigate('/')} className={styles.headerLogo}>
          <img src={theme === 'light' ? LogoDark : LogoLight} alt="logo" />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.menu_list_left}>
          {renderLink('https://app.oraidex.io', 'Home', <HomeIcon />, true)}
          {renderLink('https://futures.oraidex.io', 'Futures Trading', <FutureIcon />, true)}
        </div>
      </div>
      <div className={styles.right}>
        <div className={classNames(styles.menu_list_right)}>
          <div
            onClick={() => {
              setOtherActive(!otherActive);
            }}
            className={classNames(styles.menu_item)}
          >
            <HelpIcon />
            <span className={classNames(styles.menu_item_text)}>Help</span>
            <DownArrowIcon />
          </div>

          <TooltipContainer
            placement="auto-end"
            visible={otherActive}
            setVisible={() => setOtherActive(!otherActive)}
            content={
              <div className={classNames(styles.menu_others_list, styles[theme])}>
                {renderLink('https://t.me/oraidex', 'Join our Community', <TelegramIcon />)}
                {renderLink('https://twitter.com/oraidex', 'Twitter', <TwitterIcon />)}
                {renderLink('https://t.me/SamORAI_bot', 'Contact us', <SupportIcon />)}
              </div>
            }
          />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.connect}>
          <ConnectWallet />
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
